<template>
    <div class="container">
        <qy-top-nav title="我的关注"/>
        <van-dropdown-menu>
            <van-dropdown-item v-model="goodsStatus" :options="goodsStatusList"  @change="handleChangeGoodsStatus"/>
            <van-dropdown-item v-model="favoriteTime" :options="favoriteTimeList" @change="handleChangeFavoriteTime"/>
        </van-dropdown-menu>
        <div :style="{height: containerHeight,overflow:'auto'}" >
            <van-list
                v-model="loading"
                :finished="finished"
                :offset="offset"
                finished-text="没有更多了"
                @load="onLoad"
            >
                <van-swipe-cell v-for="item in goodsList">
                    <div class="listDetails" @click="goodsDetail(item.goodsId)">
                        <div class="shopImg">
                            <van-image
                              :src="getImgUrl(item.goodsLogo)"
                              width="130px"
                              height="130px"
                            />
                        </div>
                        <div class="goodsPresentation">
                            <div class="goodsTitle">
                                <p class="p1">{{item.goodsName}}</p>
                                <p class="p2">{{item.goodsRemark}}</p>
                            </div>
                            <div>
                                <span class="goodsPrice">¥{{formatMoney(item.wholesalePrice,"面议")}}</span>
                            </div>
                        </div>
                    </div>
                    <template #right>
                        <van-button square type="danger" text="取消关注" style="height: 100%" @click="deleteFollow(item.id)"/>
                    </template>
                </van-swipe-cell>
            </van-list>
        </div>
    </div>
</template>
<script type="text/javascript">
import request from "@/utils/request";
export default {
    name: "Favorite",
    data() {
        return {
            offset:50,
            loading:false,
            finished:false,
            pageSize: 10,
            pageNum: 1,
            goodsList:[],
            containerHeight: 0,
            goodsStatus: "-1",
            goodsStatusList: [
                { text: "全部", value: "-1" },
                { text: "上架中", value: "1" },
                { text: "已下架", value: "0" }
            ],
            favoriteTime: "-1",
            favoriteTimeList: [
                { text: "所有时间", value: "-1" },
                { text: "3天", value: "3" },
                { text: "1星期", value: "7" },
                { text: "1个月", value: "30" }

            ]
        };
    },
    methods:{
        goodsDetail(goodsId){
            this.$router.push("/goodsInfo?goodsId="+goodsId);
        },
        deleteFollow(id){
            request.post("/api/follow/delete/"+id).then(
                res => {
                    if (res.success){
                        this.goodsList = this.goodsList.filter(item=>{
                            return item.id != id;
                        });
                        this.Toast.success("删除成功");
                    }else {
                        this.Toast.fail(res.msg);
                    }
                }
            );
        },
        onLoad(){
            //是否已经登录
            let isLogin = this.isLogin();
            if(!isLogin){
                this.$router.push("/login");
                return
            }
            if(!this.finished){
                this.doRequestFavoriteList();
            }
        },
        handleChangeGoodsStatus(v){
            this.goodsStatus = v;
            this.pageNum = 1;
            this.goodsList = [];
            this.loading =false;
            this.doRequestFavoriteList();
        },
        handleChangeFavoriteTime(v){
            this.favoriteTime = v;
            this.pageNum = 1;
            this.goodsList = [];
            this.loading =false;
            this.doRequestFavoriteList();
        },
        doRequestFavoriteList(){
            let that = this;
            const db = {
                saleStatus:this.goodsStatus,
                browseTime:this.favoriteTime,
                pageNum:this.pageNum,
                pageSize:this.pageSize,
            };
            request.post("/api/follow/list",db).then(
                res => {
                    if(res.success){
                        let list = res.data.list;
                        let hasNextPage = res.data.hasNextPage;
                        if(list.length > 0){
                            that.goodsList = that.goodsList.concat(list);
                        }
                        if(!hasNextPage){
                            that.finished = true;
                            that.loading = false;
                        }else {
                            that.finished = false;
                            that.loading = false;
                            this.pageNum++;
                        }
                    }else {
                        that.finished = true;
                    }
                },
            );
        },
    },
    mounted() {
        this.containerHeight = document.documentElement.clientHeight - 100 + "px";
    }

}
</script>
<style type="text/css" scoped="">
div.container{
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    background-color:#f5f5f5;
}
.listDetails {
    display: flex;
    background-color: #ffffff;
    align-items: center;
    border-radius: 5px;
    margin: 5px 5px;
	padding:10px 0px;
}

.shopImg {
    margin: 2px 5px;
}
.van-image{width:90px!important;height:90px!important;}
.goodsTitle{
padding-left:10px;
padding-right:10px;
}
.goodsTitle .p1 {
    font-size: 15px;
	color:#333;
	text-overflow: ellipsis;display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin:0px;
  overflow:hidden;
}
.goodsPrice{color:#ee0a24;font-size:16px;margin-left:10px;}
.goodsTitle .p2 {
    color: #9c9c9c;
    background: #fbfbfb;
    padding: 4px 2px;
    height: 2.5em;
    overflow: hidden;
	text-overflow: ellipsis;display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin:0px;
  overflow:hidden;
}
.goodsPrice{margin-top:10px;display:block;padding-left:10px;}
.van-swipe-cell{margin:5px;}

</style>